import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { Navigate } from 'react-router-dom';
import { element } from 'prop-types';

// sample page routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));


//Companies
const CompaniesList = Loadable(lazy(()=> import('views/pages/onboarding/companies/CompaniesList')));
const CompanyForm = Loadable(lazy(()=> import ('views/pages/onboarding/companies/CompanyForm')));

//Resorts
const ResortsList = Loadable(lazy(()=> import('views/pages/resorts/ResortsList')));
const ResortForm = Loadable(lazy(()=> import ('views/pages/resorts/ResortForm')));

const ResortBusinessNumberList = Loadable(lazy(()=> import('views/pages/resorts/ResortBusinessNumberList')));
const ResortBusinessNumberForm = Loadable(lazy(()=> import('views/pages/resorts/ResortBusinessNumberForm')));

const ResortExportForm = Loadable(lazy(()=> import ('views/pages/resorts/ResortExport')));
const ResortTriggerList = Loadable(lazy(()=> import ('views/pages/resorts/ResortTriggerAPIList')));
const ResortTriggerForm = Loadable(lazy(()=> import ('views/pages/resorts/ResortTriggerAPIForm')));

const ReservationList = Loadable(lazy(()=>import('views/pages/webhooks/reservations_list')));
const ReservationForm = Loadable(lazy(()=>import('views/pages/webhooks/reservation_detail')));
const GuestInformation = Loadable(lazy(()=>import('views/pages/webhooks/guest_detail')));

const OrdersList = Loadable(lazy(()=>import('views/pages/webhooks/orders_list')));
const OrderForm = Loadable(lazy(()=>import('views/pages/webhooks/order_detail')));

const DailyReport = Loadable(lazy(()=>import ('views/pages/reports/DailyReport')))
const ReservationCodeReport = Loadable(lazy(()=>import ('views/pages/reports/ReservationCodeReport')))

//System-Users
const SystemUsersList = Loadable(lazy(()=>import('views/pages/settings/SystemUsersList')));
const SystemUserForm = Loadable(lazy(()=>import('views/pages/settings/SystemUsersForm')));

//CGT-Customers
const CGTCustomersList = Loadable(lazy(()=>import('views/pages/onboarding/CGTCustomerList')));
const CGTCustomerForm = Loadable(lazy(()=>import('views/pages/onboarding/CGTCustomerForm')));

//CGT-PHoneNumber
const CGTPhoneNumberList = Loadable(lazy(()=>import('views/pages/onboarding/CGTPhoneNumberList')));
const CGTPhoneNumberForm = Loadable(lazy(()=>import('views/pages/onboarding/CGTPhoneNumberForm')));


//CGT-RequestForm
const CGTRequestFormList = Loadable(lazy(()=>import('views/pages/onboarding/CGTRequestFormList')));
const CGTRequestForm = Loadable(lazy(()=>import('views/pages/onboarding/CGTRequestForm')));

//Webhook Request
const WebhookList = Loadable(lazy(()=>import('views/pages/webhooks/webhook_RequestList')));
const WebhookItem = Loadable(lazy(()=>import('views/pages/webhooks/webhook_RequestDetail')));

const WH_QUANT_List   = Loadable(lazy(()=>import('views/pages/webhooks/webhook_requestQNTList')));
const WH_QUANT_DETAIL = Loadable(lazy(()=>import('views/pages/webhooks/webhook_RequestDetail')));

const WebhookReport = Loadable(lazy(()=>import('views/pages/reports/WebHookReports')));
//Trigger
const TriggerList = Loadable(lazy(()=> import('views/pages/triggers/triggerList')));
const TriggerForm = Loadable(lazy(()=> import ('views/pages/triggers/triggerForm')));
const TriggerScheduleTest = Loadable(lazy(()=> import ('views/pages/triggers/trigger_schedule_test')));
const TriggerTypesList = Loadable(lazy(()=> import('views/pages/triggers/triggerTypesList')));
const TriggerTypesForm = Loadable(lazy(()=> import ('views/pages/triggers/triggerTypesForm')));
const TriggerSchedulesDetailList   = Loadable(lazy(()=> import ('views/pages/triggers/trigger_schedule_details')));
const TriggerScheduleResultDetail  = Loadable(lazy(()=> import ('views/pages/triggers/trigger_schedule_result_detail')));

const RegisterBusinessList = Loadable(lazy(()=>import('views/pages/netnumber/RegisterBusinessList')));
const RegisterBusinessForm = Loadable(lazy(()=>import('views/pages/netnumber/RegisterBusinessForm')));
const SubmitNumbersList = Loadable(lazy(()=>import('views/pages/netnumber/NetNumberSubmitList')));
const SubmitNumbersForm = Loadable(lazy(()=>import('views/pages/netnumber/NetNumberSubmitForm')));

const CampaignCompanyList = Loadable(lazy(()=>import('views/pages/onboarding/companies/CampaignCompanyList')));
const CampaignCompanyForm = Loadable(lazy(()=>import('views/pages/onboarding/companies/CampaignCompanyForm')));
const ActiveBrandList = Loadable(lazy(()=>import('views/pages/onboarding/companies/ActiveBrandList')));
const CampaignForm = Loadable(lazy(()=>import('views/pages/onboarding/campaign/CampaignForm')))
const CampaignList = Loadable(lazy(()=>import('views/pages/onboarding/campaign/CampaignList')));

//Logs
const Logs = Loadable(lazy(() => import('views/pages/settings/Logs')));

// ==============================|| MAIN ROUTING ||============================== //

export const ServiceProviderRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        
        {
            path: "campaign-registry/active-brands",
            element: <ActiveBrandList />,
        },
        {
            path:"/campaign-registry/campaign-registry",
            element:<CampaignForm />,
        },
        {
            path: "onboarding/customers",
            element: <CGTCustomersList />,
        },
        {
            path: "onboarding/campaign-companies",
            element: <CampaignCompanyList />,
        },
        {
            path: "onboarding/campaign-company/:id",
            element: <CampaignCompanyForm />,
        },
        {
            path: "onboarding/campaign/:id",
            element: <CampaignList />,
        },
        {
            path: "onboarding/cgt-phonenumbers",
            element: <CGTPhoneNumberList />,
        },
        {
            path: "onboarding/cgt-phonenumber/:id",
            element: <CGTPhoneNumberForm />,
        },
        {
            path: "onboarding/customers/:id",
            element: <CGTCustomerForm />,
        },
        {
            path: "onboarding/request-form",
            element: <CGTRequestFormList />,
        },

        {
            path: "onboarding/request-form/:id",
            element: <CGTRequestForm />,
        },
        {
            path: "trigger-schedules",
            element: <TriggerList />,
        },
        {
            path: "trigger-schedules/:id",
            element: <TriggerForm />,
        },
        {
            path: "trigger-schedule-test",
            element: <TriggerScheduleTest />,
        },
        {
            path: "trigger-schedules-details/:id",
            element: <TriggerSchedulesDetailList />,
        },
        {
            path: "trigger-schedule-result-details/:id",
            element: <TriggerScheduleResultDetail />,
        },

        {
            path: "trigger-types",
            element: <TriggerTypesList />,
        },
        {
            path:'webhook-requests-list/:customer',
            element:<WebhookList />,
        }, 
        {
            path:'webhook-requests-list/qntl',
            element:<WH_QUANT_List />,
        }, 
        {
            path:'orders',
            element:<OrdersList />,
        }, 
        {
            path:'orders/:id',
            element:<OrderForm />,
        }, 
        {
            path:'webhook/report/booked',
            element:<WebhookReport />,
        },
        {
            path:'reports/marketing',
            element:<ReservationCodeReport />,
        },
        {
            path:'webhook-request-item/:customer/:prodtype/:id',
            element:<WebhookItem />,
        },
        {
            path: "trigger-types/:id",
            element: <TriggerTypesForm />,
        },
        
        {
            path: "companies",
            element: <CompaniesList />,
        },
        {
            path: "company/new",
            element: <CompanyForm isNew='true' />,
        },
        {
            path: "company/profile/",
            element: <CompanyForm profile='true' />,
        },
        {
            path: "company/profile/:id",
            element: <CompanyForm profile='true' />,
        },
        {
            path: "company/edit/:id",
            element: <CompanyForm />,
        },
        {
            path: "resort/new",
            element: <ResortForm />,
        },
        {
            path: "resort/profile/",
            element: <ResortForm profile='true' />,
        },
        {
            path: "resort/export/:id",
            element: <ResortExportForm  />,
        },
        {
            path: "resort/profile/:id",
            element: <ResortForm profile='true' />,
        },
        {
            path: "resort/edit/:id",
            element: <ResortForm />,
        },

        {
            path: "resorts",
            element: <ResortsList />,
        },
        {
            path: "resort/phone-numbers",
            element: <ResortBusinessNumberList/>
        }
        ,
        {
            path: "resort/phone-number/profile/:id",
            element: <ResortBusinessNumberForm profile='true' />,
        },
        {
            path: "/resort/phone-number/new",
            element: <ResortBusinessNumberForm/>
                    }
                    ,
        {
            path: "resorts/triggers",
            element: <ResortTriggerList />,
        },
        {
            path: "resorts/trigger/info/new",
            element: <ResortTriggerForm />,
        },
        {
            path: "resorts/trigger/info/",
            element: <ResortTriggerForm />,
        },
        {
            path: "resorts/trigger/info/:id",
            element: <ResortTriggerForm />,
        },

        {
            path: "system-users",
            element: <SystemUsersList />,
        },
        {
            path: "system-user/profile/:id",
            element: <SystemUserForm profile='true'/>,
        },
        {
            path: "system-user/edit/:id",
            element: <SystemUserForm />,
        },
        {
            path: "system-user/new",
            element: <SystemUserForm />,
        },
        {
            path: 'reservations',
            element:<ReservationList/>
        },
        {
            path: 'reservation/:id',
            element:<ReservationForm/>
        },
        {
            path: 'guest-info/:rid/:id',
            element:<GuestInformation/>
        },
        {
            path: 'logs/:mod',
            element: <Logs replace to="/" />
        } ,
        {
            path: 'reports/daily-report',
            element:<DailyReport/>          
        },
        {
            path:'netnumber/register-business',
            element:<RegisterBusinessList/>
        },

        {
            path:'netnumber/register-business/new',
            element:<RegisterBusinessForm/>
        },
        {
            path:'netnumber/register-business/item/:id',
            element:<RegisterBusinessForm/>
        },
        {
            path:'netnumber/provision-pending',
            element:<SubmitNumbersList/>
        },
        {
            path:'netnumber/provision-submit',
            element:<SubmitNumbersForm/>
        },
        {
            path:'netnumber/submit-numbers/item/:id',
            element:<SubmitNumbersForm/>
        },
        {
            path: '*',
            element: <Navigate replace to="/" />
        }


    ]
}

export const AdminRoutes = ServiceProviderRoutes;

