import axios from "axios";
import PerformRecordValidation from "./PerformRecordValidation";
import AxiosErrorHandler from "./AxiosErrorHandler";
import APIGetAuthHeader from "./APIGetAuthHeader";

const APIGetRecords = async (options) => {

    let axiosConfig = await APIGetAuthHeader();
    //console.log((options.server ? options.server : process.env.REACT_APP_API_BASE) + options.url );
    return await axios.get((options.server ? options.server : process.env.REACT_APP_API_BASE) + options.url , axiosConfig)
    .then(async res => { console.log("SUBBESS"); return await PerformRecordValidation(res.data,options)}) 
    .catch(async err => {
      
        console.log(err?.response?.data)
        if (options.PostErrorFunction){
           if (options.showErrorBeforeFunction !== undefined && options.showErrorBeforeFunction === true )
               await AxiosErrorHandler(err,options);
           return await options.PostErrorFunction(err, options);
        }
        else
           return await AxiosErrorHandler(err,options)
      });
}
export default APIGetRecords;