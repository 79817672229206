// assets
import {
    IconSettings
} from '@tabler/icons';
import {
    IconMessages
} from '@tabler/icons';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import AppsIcon from '@mui/icons-material/Apps';
import DataArrayIcon from '@mui/icons-material/DataArray';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SettingsSystemDaydreamTwoToneIcon from '@mui/icons-material/SettingsSystemDaydreamTwoTone';
import BedroomParentTwoToneIcon from '@mui/icons-material/BedroomParentTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import ViewModuleTwoToneIcon from '@mui/icons-material/ViewModuleTwoTone';
import CloudSyncTwoToneIcon from '@mui/icons-material/CloudSyncTwoTone';
import AppRegistrationTwoToneIcon from '@mui/icons-material/AppRegistrationTwoTone';
import DialpadTwoToneIcon from '@mui/icons-material/DialpadTwoTone';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CampaignTwoToneIcon from '@mui/icons-material/CampaignTwoTone';


// constant
const icons = {
    IconSettings,HandshakeTwoToneIcon,
    IconMessages,
    ImportContactsIcon,
    AppsIcon,
    DataArrayIcon,
    AssessmentTwoToneIcon,
    ViewModuleTwoToneIcon,
    CloudSyncTwoToneIcon,
    AppRegistrationTwoToneIcon,CampaignTwoToneIcon,
    DialpadTwoToneIcon,
    RememberMeIcon,SubscriptionsIcon,ViewModuleIcon,CorporateFareIcon,SettingsSystemDaydreamTwoToneIcon,BedroomParentTwoToneIcon,QuestionAnswerIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const adminMenuItem = {
    id: 'settings-parent',
    title: 'Administrator Menu',
    type: 'group',
    children: [

        {
            id: 'onboarding',
            title: 'ONBOARDING',
            type: 'collapse',
            breadcrumbs: true,
            icon: icons.HandshakeTwoToneIcon,
            iconColor: '#30629b',
           
            children: [
                {
                    id: 'CGI-FORM',
                    title: 'CGT Enrollments',
                    type: 'item',
                    url: '/onboarding/request-form',
                    breadcrumbs: false,
                },
                {
                    id: 'CGI-CUST',
                    title: 'CGT Customers',
                    type: 'item',
                    url: '/onboarding/customers',
                    breadcrumbs: false,
                },
                {
                    id: 'CGI-PHONE',
                    title: 'CGT Phone Number',
                    type: 'item',
                    url: '/onboarding/cgt-phonenumbers',
                    breadcrumbs: false,
                },
                
               ]
        },  
        {
            id: 'conversation',
            title: 'CONVERSATION',
            type: 'collapse',
            breadcrumbs: true,
            icon: icons.QuestionAnswerIcon,
            iconColor: '#30629b',
           
            children: [
                {
                    id: 'CNV-COMP',
                    title: 'CNV Companies',
                    type: 'item',
                    url: '/companies',
                    breadcrumbs: false,
                },                
                
               ]
        },  
        {
            id: 'campaign-registry',
            title: 'CAMPAIGN',
            type: 'collapse',
            breadcrumbs: true,
            icon: icons.CampaignTwoToneIcon,
            iconColor: '#30629b',
           
            children: [
                {
                    id: 'reg-comp',
                    title: 'Active Brands',
                    type: 'item',
                    url: '/campaign-registry/active-brands',
                    breadcrumbs: false,
                },     
                {
                    id: 'reg-comp',
                    title: 'Register Campaign',
                    type: 'item',
                    url: '/campaign-registry/campaign-registry',
                    breadcrumbs: false,
                },               ]
            },  
           
        {
            id: 'net-number',
            title: 'NETNUMBER',
            type: 'collapse',
            breadcrumbs: true,
            icon: icons.DialpadTwoToneIcon,
            iconColor: '#30629b',
           
            children: [
                {
                    id: 'reg-comp',
                    title: 'Provision Company',
                    type: 'item',
                    url: '/netnumber/register-business',
                    breadcrumbs: false,
                },
                {
                    id: 'request',
                    title: 'Submit Request',
                    type: 'item',
                    url: '/netnumber/provision-pending',
                    breadcrumbs: false,
                },
               ]
        },  
       
        {
            id: 'resort-group',
            title: 'RESORTS INFO',
            type: 'collapse',
            breadcrumbs: true,
            icon: icons.CorporateFareIcon,
            iconColor: '#30629b',
            color:'#30629b',
            children: [
                {
                    id: 'resorts',
                    title: 'Resorts',
                    type: 'item',
                    url: '/resorts',
                    breadcrumbs: false,
                    color:'#30629b',
                    fontWeight:'bold'
                },
                {
                    id: 'resortbusinessnumber',
                    title: 'Phone Numbers',
                    type: 'item',
                    url: '/resort/phone-numbers',
                    breadcrumbs: false,
                },
                {
                    id: 'resortslinks',
                    title: 'Triggers API',
                    type: 'item',
                    url: '/resorts/triggers',
                    breadcrumbs: false,
                },]
        },


        {
            id: 'application-parent',
            title: 'MODULES',
            type: 'collapse',
            icon: icons.ViewModuleTwoToneIcon,
            iconColor: '#30629b',
            breadcrumbs: false,
            children: [
                {
                    id: 'triggertypes',
                    title: 'Trigger Types',
                    type: 'item',
                    url: '/trigger-types',
                    breadcrumbs: false,
                },
                {
                    id: 'triggers',
                    title: 'Trigger Scheduler',
                    type: 'item',
                    url: '/trigger-schedules',
                    breadcrumbs: false,
                },
                {
                    id: 'trigger_test',
                    title: 'Schedule Test',
                    type: 'item',
                    url: '/trigger-schedule-test',
                    breadcrumbs: false,
                },
                
                ]
            }, {
                id: 'reservation-group',
                title: 'WEBHOOK',
                type: 'collapse',
                breadcrumbs: true,
                icon: icons.CloudSyncTwoToneIcon,
                iconColor: '#30629b',
                children: [                    
                    {
                        id: 'webhooks',
                        title: 'HK Requests',
                        type: 'item',
                        url: '/webhook-requests-list/Hotel Key',
                        breadcrumbs: false,
                    },
                    {
                        id: 'reservations',
                        title: 'HK Reservations',
                        type: 'item',
                        url: '/reservations',
                        breadcrumbs: false,
                    },
                    {
                        id: 'webhooks2',
                        title: 'QNTL Requests',
                        type: 'item',
                        url: '/webhook-requests-list/qntl',
                        breadcrumbs: false,
                    },                    
                    {
                        id: 'orders',
                        title: 'QNTL Orders',
                        type: 'item',
                        url: '/orders',
                        breadcrumbs: false,
                    }
                    ,
                    {
                        id: 'messages',
                        title: 'QNTL Messages',
                        type: 'item',
                        url: '/messages/qntl',
                        breadcrumbs: false,
                    }
                    
                    ]
            },  
            {
                id: 'report-group',
                title: 'REPORTS',
                type: 'collapse',
                breadcrumbs: true,
                icon: icons.AssessmentTwoToneIcon,
                iconColor: '#30629b',
                children: [
                    {
                        id: 'booked_report',
                        title: 'Booked Report',
                        type: 'item',
                        url: '/webhook/report/booked',
                        breadcrumbs: false,
                    },
                    {
                        id: 'daily_report',
                        title: 'Schedule Daily Report',
                        type: 'item',
                        url: '/reports/daily-report',
                        breadcrumbs: false,
                    }   ,
                    {
                        id: 'marketing_code',
                        title: 'Marketing Code Report',
                        type: 'item',
                        url: '/reports/marketing',
                        breadcrumbs: false,
                    },]
            },   
            
    ]
};

export default adminMenuItem;